@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

* {
  margin: 0;
  box-sizing: border-box;
}



#contact {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-box {
  width: clamp(100px, 90%, 2000px);
  /* margin: 80px 50px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
 
}

.contact-form-wrapper {
  width: 80%;
  padding: 1% 5% 10% 5%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 15px 0 15px 0;

}


.contact-links {
  background-color: #003665;
  width: 40%;
  padding: 7% 5% 10% 5%;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


@media only screen and (max-width: 800px) {
  .contact-links, .contact-form-wrapper {
    width: 100%;
  }
  
  .contact-links {
    border-radius: 10px 10px 0 0;
  }
  
  .contact-form-wrapper {
    border-radius: 0 0 10px 10px;
  }
}

@media only screen and (max-width: 400px) {
  .contact-box {
    width: 95%;
    margin: 8% 5%;
  }
}

.h2 {
  font-family: 'Arimo', sans-serif;
  color: #fff !important;
  font-size: clamp(20px, 2vw, 30px);
  letter-spacing: 2px;

  transform: scale(.95, 1);
}

.links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 50px;
}

.link {
  margin: 10px;
  cursor: pointer;
}

/* .img {
  width: 45px;
  height: 45px;
  filter: 
    hue-rotate(220deg)
    drop-shadow(2px 4px 4px #0006);
  transition: 0.2s;
  user-select: none;
}

.img:hover {
  transform: scale(1.1, 1.1);
}

.img:active {
  transform: scale(1.1, 1.1);
  filter: 
    hue-rotate(220deg)
    drop-shadow(2px 4px 4px #222)
    sepia(0.3);
} */

.form-item {
  position: relative;
}



/* .input, textarea {
  width: 100%;
  outline: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 12px;
  font-size: clamp(15px, 1.5vw, 18px);
}

.input:focus+label, 
.input:valid+label, 
.textarea:focus+label, 
.textarea:valid+label {
  font-size: clamp(13px, 1.3vw, 16px);
  color: #777;
  top: -20px;
  transition: all .225s ease;
}

.submit-btn {
  background-color:#1089ff;
  filter: drop-shadow(2px 2px 3px #0003);
  color: #fff;
  font-family: "Poppins",sans-serif;
  font-size: clamp(16px, 1.6vw, 18px);
  display: block;
  padding: 12px 20px;

  border: none;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
}

.submit-btn:hover {
  transform: scale(1.1, 1.1);
}

.submit-btn:active {
  transform: scale(1.1, 1.1);
  filter: sepia(0.5);
} */

@media only screen and (max-width: 800px) {
  .h2 {
    font-size: clamp(40px, 10vw, 60px);
  }
}

@media only screen and (max-width: 400px) {
  .h2 {
    font-size: clamp(30px, 12vw, 60px);
  }
  
  .links {
    padding-top: 30px;
  }
  
  .img {
    width: 38px;
    height: 38px;
  }
}
.bg-icon{
    background-color: #98c2ec;
    color: white!important;

  padding: 19px;
  border-radius: 50px;
}

.form-hadding{
  color: #003665;
}


.scrollbar{
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth; 
  overflow-y: auto;
  overflow-x: hidden; 
  height: 100vh;
}


/* table.table tr th, table.table tr td {
  border-color: #000000;
  border: 2px solid !important;
  vertical-align: middle;
} */


/* .table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(10, 131, 10, 0.5) !important; 
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffffff !important;
} */
