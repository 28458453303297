.btn-close{
    /* background-color: rgb(0, 54, 101) !important; */
    opacity: inherit !important;
    /* color: white !important; */
}

table.table tr td {
    padding: 5px 5px;
}
table.table tr th, table.table tr td {

    padding: 5px 5px;
}