/* .card {
    text-align: center;
    box-shadow: 0px 0px 3px 3px #dddddd;
    height: auto;
    width: 280px;
    padding: 10px 15px;
    border-radius: 10px;
} */
.card .title {
    font-size: 2em;
    margin: 15px;
    justify-content: center;
    text-align: center;
}
.card .form {
    display: flex;
    align-items: center;
    justify-content: center;

}
.form label {
    cursor: pointer;
}
.card .form .input-radio {
    box-shadow: 0px 0px 0px 1px #6d6d6d;
    font-size: 3em;
    width: 25px;
    height: 25px;
    margin-right: 7px;

    border: 4px solid #fff;
    background-clip: border-box;
    border-radius: 50%;
    appearance: none;
    transition: background-color 0.3s, box-shadow 0.3s;

}
.card .form label {
    display: flex;
    margin: 10px 15px;
}

.input-radio.on:checked {
    box-shadow: 0px 0px 0px 4px rgb(0, 54, 101);
    background-color: rgba(0, 54, 101, 0.863);
}
.input-radio.off:checked {
    box-shadow: 0px 0px 0px 4px rgb(0, 54, 101);
    background-color: rgba(0, 54, 101, 0.863);
}
