/* .zui-table {
    border: none;
    border-right: solid 1px #DDEFEF;
    border-collapse: separate;
    border-spacing: 0;
    font: normal 13px Arial, sans-serif;
  }
  
  .zui-table thead th {
    background-color: rgb(204, 204, 204);
    border: none;
    color: black;
    padding: 10px;
    text-align: left;
    white-space: nowrap;
    line-height: 1; 
  }
  
  .zui-table tbody td {
    color: black;
    padding: 5px; 
    text-align: left;
    white-space: nowrap;
    line-height: 1; 
  }
  
  .zui-wrapper {
    position: relative;
  }
  
  .zui-scroller {
    
    overflow-x: scroll;
    overflow-y: visible;
    padding-bottom: 5px;
    width: auto;
  }*/
  
  /* .zui-table .zui-sticky-col {
    left: 0px;
    position: sticky;
    position: -webkit-sticky; 
    top: 0;
    width: 295px;
    background-color: rgb(204, 204, 204);
  }
   */
  /* .zui-table .zui-sticky-col2 {
    left: 60px;
    position: sticky;
    position: -webkit-sticky; 
    top: 0;
    width: 150px;
    background-color: rgb(204, 204, 204);
  }
  
  .zui-table .zui-sticky-col3 {
    left: 170px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    width: 125px;
    background-color: rgb(204, 204, 204);
  }
   */ 



   /* .zui-table {
    border: none;
    border-right: solid 1px #DDEFEF;
    border-collapse: separate;
    border-spacing: 0;
    font: normal 13px Arial, sans-serif;
  }
  
  .zui-table thead th {
    background-color: rgb(204, 204, 204);
    border: none;
    color: black;
    padding: 10px;
    text-align: left;
    white-space: nowrap;
    line-height: 1; 
  }
  
  .zui-table tbody td {
    color: black;
    padding: 5px; 
    text-align: left;
    white-space: nowrap;
    line-height: 1; 
  }
  
  .zui-wrapper {
    position: relative;
  }
  
  .zui-scroller {
   
    overflow-x: scroll;
    overflow-y: visible;
    padding-bottom: 5px;
    width: auto;
  }*/
  
  .zui-table .zui-sticky-col {
    left: 0px;
    position: sticky;
    position: -webkit-sticky; 
    top: 0;
    width: 295px;
    background-color: rgb(204, 204, 204);
  }
  
  .zui-table .zui-sticky-col2 {
    left: 60px !important;
    position: sticky;
    position: -webkit-sticky; 
    top: 0;
    width: 150px;
    background-color: rgb(204, 204, 204);
  }
  
  .zui-table .zui-sticky-col3 {
    left: 140px !important;
    position: sticky;
    position: -webkit-sticky; 
    top: 0;
    width: 130px;
    background-color: rgb(204, 204, 204);
  } 


  .zui-table .zui-sticky-col4 {
    left: 220px !important;
    position: sticky;
    position: -webkit-sticky; 
    top: 0;
    width: 125px;
    background-color: rgb(204, 204, 204);
  } 


  .zui-table .zui-sticky-col5 {
    left: 291px !important;
    position: sticky;
    position: -webkit-sticky; 
    top: 0;
    width: 125px;
    background-color: rgb(204, 204, 204);
  } 
  
  .css-1nmdiq5-menu{
    z-index: 5 !important;
  }