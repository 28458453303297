.grid-container {
    display: grid;
    grid-gap: 0px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* grid-auto-rows: 50px; */
  }
  .dropArea {
    position: relative;
  }
  .dropArea::before {
    content: '';
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #ebebeb;
  }
  