.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(240, 238, 238);
    color: gray;
    /* text-align: center; */
    border-top: 1px solid rgb(219, 219, 219);
 }
 /* strong{
    margin-left: 280px;
 } */