
.table-responsive {
    overflow-x: auto;
    /* height: 0% !important; */
  }
  

  
  @media (max-width: 768px) {
    .table-responsive {
      width: 100%;
      margin-bottom: 1rem; 
      overflow-x: scroll; 
    }
  
    .table {
      width: 100%;
    }
  
    .table td, .table th {
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis; 
    }
  }