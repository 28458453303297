.loginform{
    background-color: #466C8F;
}


.iconset{
    position: absolute;
    left: 90%;
    top: 18%;
}

.input-group-form{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 60%;
    margin: auto;
    
}

.btn, .btn-primary{
    background-color: #003665;
}
.btn:hover, .btn-primary:hover, .btn-primary:focus{
    border: solid 1px #003665;
    background-color: #003665;
}

.forgotpos{
    margin-left: 26%;
    
}


@media (max-width: 1000px) { 
    .onScreenChange{
        display: none;
    }
 }



 .field-icon{
    position: absolute;
    top: 50%;
    right: 15px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #000;
    z-index: 10000;
 }

 .test{
    height: 100vh;
 }

 .error{
    margin-left: 28%;
    color: #ff8800;
 }
 