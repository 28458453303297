.form-select {
    background-image: url('data:image/svg+xml,<svg width="64px" height="64px" viewBox="-21.6 -21.6 67.20 67.20" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="%23000000" stroke-width="1.44"><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="-21.6" y="-21.6" width="67.20" height="67.20" rx="0" fill="%23eeafe6" strokewidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M4.29289 8.29289C4.68342 7.90237 5.31658 7.90237 5.70711 8.29289L12 14.5858L18.2929 8.29289C18.6834 7.90237 19.3166 7.90237 19.7071 8.29289C20.0976 8.68342 20.0976 9.31658 19.7071 9.70711L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L4.29289 9.70711C3.90237 9.31658 3.90237 8.68342 4.29289 8.29289Z" fill="%23000000"></path> </g></svg>');
    background-repeat: no-repeat;
    background-size: 40px 39px;
    background-position: right -0.09rem center;
    font-size: 13px;
    padding: 0.475rem 2.25rem 0.475rem 0.75rem;
  }


  .css-1xc3v61-indicatorContainer{
    /* background-color: darkseagreen; */
    /* background-image: url('data:image/svg+xml,<svg width="64px" height="64px" viewBox="-21.6 -21.6 67.20 67.20" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="%23000000" stroke-width="1.44"><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="-21.6" y="-21.6" width="67.20" height="67.20" rx="0" fill="%23eeafe6" strokewidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"></g></svg>') !important; */
    background-repeat: no-repeat;
    background-size: 40px 39px;
    background-position: right -0.09rem center;
  }


  

  /* .css-1xc3v61-indicatorContainer{
    background-image: url('data:image/svg+xml,<svg fill="%23800080" width="800px" height="800px" viewBox="-2 -4 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-rectangle" {...props} ><path d="M3 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3zm0-2h14a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3z" /></svg>') !important;
    background-repeat: no-repeat;
    background-size: 40px 39px;
    background-position: right -0.09rem center;
  } */


  .css-15lsz6c-indicatorContainer{
    /* background-color: darkseagreen; */
    /* background-image: url('data:image/svg+xml,<svg width="64px" height="64px" viewBox="-21.6 -21.6 67.20 67.20" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="%23000000" stroke-width="1.44"><g id="SVGRepo_bgCarrier" stroke-width="0"><rect x="-21.6" y="-21.6" width="67.20" height="67.20" rx="0" fill="%23eeafe6" strokewidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"></g></svg>') !important; */
    background-repeat: no-repeat;
    background-size: 40px 39px;
    background-position: right -0.09rem center;
  }


  .css-1u9des2-indicatorSeparator{
    width: 0px !important;
  }


  .css-1xc3v61-indicatorContainer{
    color: black !important;
  }




  .dropdown-item.active,
  .dropdown-item:active {
    background-color: transparent !important;
  }
   /* ::-webkit-scrollbar {
    display: none;
  } */
  
  /* ::-webkit-scrollbar-button {
    display: None;
  } */

  /* ::-webkit-scrollbar-thumb {
    display: block;
    color: aqua;
  } */
  
  /* body {
    font-size: 13px;
  } */

  /* html {font-size: 58.3%;} */

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  /* body{
    height: 100%;
    min-height: 1200px;
    max-height: auto;
    overflow: hidden;
  } */
  

  


 




  button:focus {
    outline-width: 3px;
    outline-style: solid;
    outline-color: rgb(0, 0, 0);
  }

  .content-wrapper{
    font-size: 13px;
  }









  /* scrollBra */



  :root {
    --code-color: darkred;
    --code-bg-color: #aaaaaa;
    --code-font-size: 14px;
    --code-line-height: 1.4;
    --scroll-bar-color: #c5c5c5;
    --scroll-bar-bg-color: #f6f6f6;
}

pre {
    color: var(--code-color);
    font-size: var(--code-font-size);
    line-height: var(--code-line-height);
    background-color: var(--code-bg-color);
}

.code-block {
    max-height: 100px;
    overflow: auto;
    padding: 8px 7px 5px 15px;
    margin: 0px 0px 0px 0px;
    border-radius: 7px;
}

::-webkit-scrollbar-corner { background: rgba(0,0,0,0.5); }

* {
    scrollbar-width: thin;
    scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 15px;
    height: 15px;
}

*::-webkit-scrollbar-track {
    background: var(--scroll-bar-bg-color);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--scroll-bar-color);
    border-radius: 20px;
    border: 3px solid var(--scroll-bar-bg-color);
}

thead{
  cursor: pointer;
}

::placeholder{
  font-size: 13px;

}
option{
  font-size: 13px;

}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
 /* body{
  height: 100%;
  min-height: 1200px;
  max-height: auto;
  overflow: hidden;
} */


  @media only screen and (max-width:  1920px) and (min-width: 1767px)  {
    .table-responsive {
      height: 42%;
      width: 100%;
      max-height: 653px;
      min-height: 240px;
    }
  }
  @media only screen and (max-width:  1768px) and (min-width: 1680px)  {
    .table-responsive {
      height: 42%;
      width: 100%;
      max-height: 557px;
      min-height: 240px;
    }
  }
  @media only screen and (max-width:  1681px) and (min-width: 1600px)  {
    .table-responsive {
      height: 42% ;
      width: 100% ;
      max-height: 616px ;
      min-height: 240px ;
    }
  }
 
  @media only screen and (max-width:  1601px) and (min-width: 1366px)  {
    .table-responsive {
      /* height: 42% !important; */
      width: 100% !important;
      max-height: 476px !important;
      min-height: 100px !important;
    }
  }
  @media only screen and (max-width:  1367px) and (min-width: 1360px)  {
    .table-responsive {
      height: 42% !important;
      width: 100% !important;
      max-height: 339px !important;
      min-height: 240px !important;
    }
  }
  @media only screen and (max-width:  1280px) and (min-width: 1175px)  {
    .table-responsive {
      /* height: 42% !important; */
      width: 100% !important;
      /* max-height: 290px !important; */
      /* min-height: 240px !important; */
    }
  }
  /* @media only screen and (max-width:  1280px) and (min-width: 1175px)  {
    .table-responsive {
      height: 42% !important;
      width: 100% !important;
      max-height: 290px !important;
      min-height: 240px !important;
    }
  } */
  @media only screen and (max-width:  1176px) and (min-width: 1151px)  {
    .table-responsive {
      height: 42% !important;
      width: 100% !important;
      max-height: 240px !important;
      min-height: 220px !important;
    }
  }
  @media only screen and (max-width:  1152px) and (min-width: 1023px)  {
    .table-responsive {
      height: 42% !important;
      width: 100% !important;
      max-height: 343px !important;
      min-height: 220px !important;
    }
  }
  @media only screen and (max-width:  1024) and (min-width: 799px)  {
    .table-responsive {
      height: 42% !important;
      width: 100% !important;
      max-height: 340px !important;
      min-height: 220px !important;
    }
  }
  @media only screen and (max-width:  800)and (min-width: 300px)  {
    .table-responsive {
      height: 42% !important;
      width: 100% !important;
      max-height: 240px !important;
      min-height: 220px !important;
    }
  }

  .clearfix{
    padding: 0 !important;
  }
  
  .dropdown-select{
    width: 88% !important;
    padding-right: 5px;
  }

  input[type="text"]
{
    font-size:13px;
}
input[type="number"]
{
    font-size:13px;
}
input[type="email"]
{
    font-size:13px !important;
}

.ant-picker .ant-picker-input >input{
  font-size:13px;
}






.___SPickerInput_1ove7_gg_._size_l_1ove7_gg_, .___SPickerInput_1ove7_gg_._size_m_1ove7_gg_ {

  width: 230px;

}



.react-datepicker-wrapper{
width: 100%;
}


.react-datepicker__view-calendar-icon input {
padding: 6px 10px 5px 10px;
}




.css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
padding: 1.5px !important;
}

.css-18e93xo-MuiAutocomplete-root .MuiOutlinedInput-root{
  padding: 1.5px !important;
}

.css-i4bv87-MuiSvgIcon-root {
display: none !important;

}

#combodefault .col-lg-9 {
  min-height: 250px;
}
#default {
  margin: 0 auto;
  width: 250px !important;
  padding-top: 15px;
}
#filter-property .property-panel-content{
  padding: 10px;
}


/* PickList */

.p-button	{
  background: rgb(0, 54, 101) !important;
  padding: 5px !important;
}
.p-picklist-item{
padding: 0px 5px 0px 5px !important;
/* color: rgb(0, 54, 101) !important; */
}
.p-picklist-item[aria-selected="true"]{
color: rgb(0, 54, 101) !important;
}
.p-picklist-header{
  text-align: center !important;
  padding: 2px !important;
}
.p-picklist-filter-container{
padding: 5px 10px 5px 10px !important;

}
.p-picklist-list .p-picklist-source{
padding: 0px !important;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
padding: 5px !important;

}



span[style="white-space:nowrap"]{
  display: none !important;
}


.css-1nmdiq5-menu{
  padding: 10px !important;
}

/* body{
  cursor: none ;
  
pointer-events: none ;
} */

.css-1dimb5e-singleValue {

    font-size: 13px !important;

}
.css-qbdosj-Input{

    font-size: 13px !important;

}
textarea{
  font-size: 13px !important;
}
label{
  font-size: 13px !important;
}
/* .ant-picker-dropdown .css-dev-only-do-not-override-1e3x2xa .ant-picker-dropdown-placement-topLeft{
  z-index: 200000 !important;
} */



.ant-picker-panel-container{
z-index: 2000;
}

.fade{
  z-index: 999;
}

.modal-backdrop{
  z-index: 999;
}

.show{
  z-index: 10000;
}


.css-1nmdiq5-menu> div > div:hover{
  background:#DEEBFF
  }


/* .css-1nmdiq5-menu> div {
  padding: 5px 10px 5px 10px;
  padding: 10px;
  } */