



  .bordered {
    /* border: 1px solid #000000; */
    padding: 3px;
}

.bordered-row {
    /* border: 1px solid #000000; */
    margin-bottom: 10px;
}


.rounded-row {
    /* border: 1px solid #000000; */
    border-radius: 5px; 
    margin-bottom: 10px;
    padding: 10px;
}

/* .bordered p:not(:last-child) {
    border-bottom: 1px solid #000000;
    margin-bottom: 5px; 
} */

/* .vertical-line {
    border-right: 1px solid black; 
} */
/* @media (min-width: 998px) { 
  
    .bottom-line {
        border-bottom: none !important;
       
    }
}
@media (max-width: 998px) { 
    .vertical-line {
        border-right: none !important; 
    }
    .alignItems{
        justify-content: left !important;
    }
    
}
@media (max-width: 768px) {
    .vertical-line {
        border-right: none !important; 
    }
    .alignItems{
        justify-content: left !important;
    }
 
}
.bottom-line {
    border-bottom: 1px solid black;
    padding: 3; 
} */
/* .paddingSet{
    padding: inherit;
} */


/* .tbody, td, tfoot, th, thead, tr{
    border: 1px solid black;
} */


.table-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
}

.table-container .table {
    padding: 10px;
    width: 100%; 
  
}
/* th,td{
    text-align: center;
} */


.remove-border td, .remove-border th {
    border: none !important;
}


/* table.table tr th, table.table tr:last-child td{
    padding: 0.1px;
} */




.accordion-button{
    font-size: 13px ;
    background-color: #343a40;
    color: white;
    height: 30px;
}





  .ul {
            list-style-type: none;
            padding-left: 20px;
        }

        .ul .li::before {
            content: "\2022"; 
            color: #007bff; 
            /* font-weight: bold;  */
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }







        /* shine Card */
        /* body {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 100vh;
            margin: 0;
            background-color: #f0f0f0;
        } */
        
        .cardShine {
            position: relative;
            width: 95%;
            height: 100px;
            background-color: #ffffff;
            border-radius: 20px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            overflow: hidden;
        }

        @media only screen and (max-width: 1600px) {
            .cardShine {
               
                height: auto;
               
            }
          }

          @media only screen and (max-width: 978px) {
            .cardShine {
               
                height: auto;
               
            }
          }
        
        .shine {
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
            animation: shineAnimation 3s infinite linear;
        }
        
        .content {
            padding: 20px;
            width: inherit;
        }
        
        h2 {
           
            margin: 0;
        }
        
        p {
           
            margin: 0;
        }
        
        @keyframes shineAnimation {
            0% {
                transform: translateX(-100%);
            }
            100% {
                transform: translateX(100%);
            }
        }


       



.card{
    margin-bottom: 0px !important
}



        @media (max-width: 768px) {
            .card {
                flex-basis: calc(50% - 20px);
            }
        }
    
        @media (max-width: 576px) {
            .card {
                flex-basis: calc(100% - 20px);
            }
        }





     
        .zui-table {
            border: none;
            border-right: solid 1px #DDEFEF;
            border-collapse: separate;
            border-spacing: 0;
            font: normal 13px Arial, sans-serif;
          }
          .zui-table tbody tr td input{
            text-align: center;
          }
          
          .zui-table thead th {
            background-color: rgb(204, 204, 204);
            border: none;
            color: black;
            padding: 10px;
            text-align: left;
            white-space: nowrap;
            line-height: 1; /* Ensure consistent header height */
            text-align: center;
            border: 1px solid black;
          }
          
          .zui-table tbody td {
            color: black;
            padding: 5px; /* Reduce padding for each row */
            text-align: left;
            white-space: nowrap;
            line-height: 1; /* Ensure consistent row height */
            text-align: center;
            border: 1px solid black;
          }
          
          .zui-wrapper {
            position: relative;
          }
          
          .zui-scroller {
            /* margin-left: 290px; */
            overflow-x: scroll;
            overflow-y: visible;
            padding-bottom: 5px;
            width: auto;
          }
          
          .zui-table .zui-sticky-col {
            left: 0px;
            position: sticky;
            position: -webkit-sticky; /* For Safari */
            top: 0;
            width: 295px;
            background-color: rgb(204, 204, 204);
          }
          
          .zui-table .zui-sticky-col2 {
            left: 60px;
            position: sticky;
            position: -webkit-sticky; /* For Safari */
            top: 0;
            width: 150px;
            background-color: rgb(204, 204, 204);
          }
          
          .zui-table .zui-sticky-col3 {
            left: 170px;
            position: sticky;
            position: -webkit-sticky; /* For Safari */
            top: 0;
            width: 125px;
            background-color: rgb(204, 204, 204);
          }
          

       


       


        /* @media (max-width: 1300px) { 
            .table-responsive {
                min-height: 300px !important;
            }
            
        } */





      