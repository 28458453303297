

/* .container {
    display: flex;
    flex-direction: column;
    font-size: 10px;
} */

.card {
    border: 1px solid #ccc;
    padding: 10px;
}

.blue-box {
    background-color: #DDF1FF;
    border-radius: 15px;
    padding: 10px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.header-row {
    display: flex;
    background-color: rgb(0, 54, 101);
    border-radius: 15px;
    padding: 10px;
    color: white;
    margin-top: 8px;
}

.col {
    flex-grow: 1;
}

.bg-light {
    background-color: #f5f5f5;
    /* border-radius: 6px; */
    padding: 5px;
    text-align: center;
    width: 100px;
}

.centered-text {
    text-align: center;
}

.header {
    /* font-size: 15px; */
    padding-bottom: 8px;
}

.header-text {
    font-size: 10px;
    padding-left: 20px;
}

.amount {
    padding-bottom: 8px;
}

img.img {
    height: 60px;
    width: 70px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table, th, td {
    /* border: 1px solid #ccc; */
    padding: 6px;
}
