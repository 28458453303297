.customOptions{
    background: rgb(15, 137, 194) !important;
    color: white;
    border: 0px solid black;
    border-radius: 5px;
}

.deleteOptions{
    background: rgb(194, 15, 39) !important;
    color: white;
    border: 0px solid black;
    border-radius: 5px;
}