


/* body {
    color: #566787;
    background: #f5f5f5;
    font-family: 'Varela Round', sans-serif;
    font-size: 13px;
} */
.table-wrapper {
    
    /* padding: 20px 25px; */
    /* margin: 10px 0; */
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
.table-title {        
    padding-bottom: 15px;
    
    color: #000;
border-bottom:1px solid grey;
    padding: 9px 10px;
    /* margin: -20px -25px 10px; */
    border-radius: 3px 3px 0 0;
}

thead{
    background-color: #aaaaaa;
}

.table-title h2 {
    margin: 5px 0 0;
    font-size: 24px;
}
.table-title .btn-group {
    float: right;
}
.table-title .btn {
    color: #fff;
    float: left;
    /* font-size: 13px; */
    border: none;
    min-width: 50px;
    border-radius: 2px;
    border: none;
    outline: none !important;
    margin-left: 10px;
}
.table-title .btn i {
    float: left;
    font-size: 21px;
    margin-right: 5px;
}
.table-title .btn span {
    float: left;
    margin-top: 2px;
}


table.table tr th, table.table tr td {
    border-color: #000000;
    /* border: 2px solid; */
    /* padding: 5px 5px; */
    /* padding: 0px; */
    vertical-align: middle;
}
/* table.table tr th:first-child {
    width: 300px;
} */
/* table.table th[title="Toggle All Rows Selected"]{
    width: 60px;
} */

table.table tr th:last-child {
    /* width: 100px; */
}
table.table-striped tbody tr:nth-of-type(even) {
    background-color: #99999977;
    
}
table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #ffffff;
    
}
table.table-striped.table-hover tbody tr:hover {
    
}
table.table th i {
    font-size: 13px;
    margin: 0 5px;
    cursor: pointer;
}	
table.table td:last-child i {
    opacity: 0.9;
    font-size: 22px;
    margin: 0 5px;
}
table.table td a {
    font-weight: bold;
    color: #566787;
    display: inline-block;
    text-decoration: none;
    outline: none !important;
}
table.table td a:hover {
    color: #2196F3;
}
table.table td a.edit {
    color: #FFC107;
}
table.table td a.delete {
    color: #F44336;
}
table.table td i {
    font-size: 19px;
}
table.table .avatar {
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
}
.pagination {
    float: right;
    margin: 0 0 5px;
}
.pagination li a {
    border: none;
    font-size: 13px;
    min-width: 30px;
    min-height: 30px;
    color: #999;
    /* margin: 0 2px; */
    line-height: 30px;
    border-radius: 2px !important;
    text-align: center;
    padding: 0 6px;
}
.pagination li a:hover {
    color: #666;
}	
.pagination li.active a, .pagination li.active a.page-link {
    background: darkblue;
}
.pagination li.active a:hover {        
    background: #0397d6;
}
.pagination li.disabled i {
    color: #ccc;
}
.pagination li i {
    font-size: 16px;
    padding-top: 6px
}
.hint-text {
    float: left;
    margin-top: 10px;
    font-size: 13px;
}    
/* Custom checkbox */
.custom-checkbox {
    position: relative;
}
.custom-checkbox input[type="checkbox"] {    
    opacity: 0;
    position: absolute;
    margin: 5px 0 0 3px;
    z-index: 9;
}
.custom-checkbox label:before{
    width: 18px;
    height: 18px;
}
.custom-checkbox label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    background: white;
    border: 1px solid #bbb;
    border-radius: 2px;
    box-sizing: border-box;
    z-index: 2;
}
.custom-checkbox input[type="checkbox"]:checked + label:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 3px;
    width: 6px;
    height: 11px;
    border: solid #000;
    border-width: 0 3px 3px 0;
    transform: inherit;
    z-index: 3;
    transform: rotateZ(45deg);
}
.custom-checkbox input[type="checkbox"]:checked + label:before {
    border-color: darkblue;
    background: darkblue;
}
.custom-checkbox input[type="checkbox"]:checked + label:after {
    border-color: #fff;
}
.custom-checkbox input[type="checkbox"]:disabled + label:before {
    color: #b8b8b8;
    cursor: auto;
    box-shadow: none;
    background: #ddd;
}
/* Modal styles */

/* .modal .modal-content {
    border-radius: 3px;
} */
/* .modal .modal-footer {
    background: #ecf0f1;
    border-radius: 0 0 3px 3px;
} */
/* .modal .modal-title {
    display: inline-block;
}
.modal .form-control {
    border-radius: 2px;
    box-shadow: none;
    border-color: #dddddd;
}
.modal textarea.form-control {
    resize: vertical;
}
.modal .btn {
    border-radius: 2px;
    min-width: 100px;
}	
.modal form label {
    font-weight: normal;
}	 */

.table-bordered>thead>tr>th:hover {
/* border : none */
background: #dee2e6;
}
.table-bordered>thead{
background:grey,

}





/* alt='on' */



input[alt='on'] {
  display: none;
}
input[alt='on'] + label {
  font-size: 1em;
  line-height: 1;
  width: 4.7rem;
  height: 1.5rem;
  background-color: #197f15;
  background-image: none;
  border-radius: 2rem;
  padding: 0.1666666667rem;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  position: relative;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2) inset;
  font-family: inherit;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  margin-bottom: 0;
  margin-top: 6px;

}
input[alt='on'] + label:before {
  /* Label */
  text-transform: uppercase;
  color: #ffffff;
  content: attr(data-off-label);
  display: block;
  font-family: inherit;
  font-family: FontAwesome, inherit;
  font-weight: 500;
  font-size: 0.6rem;
  line-height: 1rem;
  position: absolute;
  right: 1.5166666667rem;
  margin: 0.2166666667rem;
  top: 0;
  text-align: center;
  min-width: 1.6666666667rem;
  overflow: hidden;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
input[alt='on'] + label:after {
  /* Slider */
  content: '';
    top:0.138rem;

  position: absolute;
  left: 3.3566666667rem;
  background-color: #f7f7f7;
  box-shadow: none;
  border-radius: 2rem;
  height: 1.22rem;
  width: 1.22rem;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
input[alt='on']:checked + label {
  background-color: rgb(226, 44, 21);
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.15)), to(rgba(0, 0, 0, 0.2)));
  background-image: linear-gradient(rgba(255, 255, 255, 0.15), rgba(0, 0, 0, 0.2));
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3) inset;
}
input[alt='on']:checked + label:before {
  color: #fff;
  content: attr(data-on-label);
  right: auto;
  left: 1.3166666667rem;
}
input[alt='on']:checked + label:after {
  left: 0.15rem;
  top:0.1rem;
  background-color: #f7f7f7;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.3);
}


/* alt==off */

input[alt='off'] {
    display: none;
  }
  input[alt='off'] + label {
    font-size: 1em;
    line-height: 1;
    width: 4.7rem;
    height: 1.5rem;
    background-color: #c71414;
    background-image: none;
    border-radius: 2rem;
    padding: 0.1666666667rem;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    position: relative;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2) inset;
    font-family: inherit;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    margin-bottom: 0px;
    margin-top: 6px;
  }
  input[alt='off'] + label:before {
    /* Label */
    text-transform: uppercase;
    color: #ffffff;
    content: attr(data-off-label);
    display: block;
    font-family: inherit;
    font-family: FontAwesome, inherit;
    font-weight: 500;
    font-size: 0.6rem;
    line-height: 1rem;
    position: absolute;
    right: 0.2166666667rem;
    margin: 0.2166666667rem;
    top: 0;
    text-align: center;
    min-width: 1.6666666667rem;
    overflow: hidden;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }
  input[alt='off'] + label:after {
    /* Slider */
    content: '';
      top:0.138rem;
  
    position: absolute;
    left: 0.1666666667rem;
    background-color: #f7f7f7;
    box-shadow: none;
    border-radius: 2rem;
    height: 1.22rem;
    width: 1.22rem;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }
  input[alt='off']:checked + label {
    background-color: rgb(39, 172, 21);
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.15)), to(rgba(0, 0, 0, 0.2)));
    background-image: linear-gradient(rgba(255, 255, 255, 0.15), rgba(0, 0, 0, 0.2));
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3) inset;
  }
  input[alt='off']:checked + label:before {
    color: #fff;
    content: attr(data-on-label);
    right: auto;
    left: 0.2166666667rem;
  }
  input[alt='off']:checked + label:after {
    left: 3.35rem;
    top:0.1rem;
    background-color: #f7f7f7;
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.3);
  }



/* input[alt='on'="on"] + label {
  background-color: #ee6562;
}
input[alt='on'="on"] + label:before {
  color: #fff !important;
}
input[alt='on'="on"]:checked + label {
  background-color: #BCE954;
}
input[alt='on'="on"]:checked + label:before {
  color: #fff !important;
}

input[alt='on'="default"]:checked + label {
  background-color: #a2a2a2;
}
input[alt='on'="default"]:checked + label:before {
  color: #fff !important;
}

input[alt='on'="success"]:checked + label {
  background-color: #BCE954;
}
input[alt='on'="success"]:checked + label:before {
  color: #fff !important;
}

input[alt='on'="warning"]:checked + label {
  background-color: gold;
}
input[alt='on'="warning"]:checked + label:before {
  color: #fff !important;
} */

.moreDropdown{
    height: 40px;
    border-radius: 8px;
}
.excelColor{
    color: green;
    font-size: larger;
    font: bolder;
}

.pdfColor{
    color: red;
    font-size: larger;
    font: bolder;
}
.printColor{
    color: black;
    font-size: larger;
    font: bolder;
}
.copyColor{
    color: skyblue;
    font-size: larger;
    font: bolder;
}
.csvColor{
    color: green;
    font-size: larger;
    font: bolder;
}
.deleteColor{
    color: red;
    font-size: larger;
    font: bolder;
}


.setCheck{
    padding: 10px !important; 
    margin-left: 10px !important
}


.table-row{
    display: flex;
    width: 1650px;
    height: 37px !important;
}
.btn{
    padding: 2px 10px;
}

