


h2,
h5,
.h2,
.h5 {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: .5rem;
  color: #32325d;
}

h5,
.h5 {
  font-size: .8125rem;
}

@media (min-width: 992px) {
  
  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

@media (min-width: 1200px) {
  
  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }
  
  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
}


.bg-danger {
  background-color: #f5365c !important;
}



@media (min-width: 1200px) {
  
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
}


.pt-5 {
  padding-top: 3rem !important;
}

.pb-8 {
  padding-bottom: 8rem !important;
}

@media (min-width: 768px) {
  
  .pt-md-8 {
    padding-top: 8rem !important;
  }
}

@media (min-width: 1200px) {
  
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
}




.font-weight-bold {
  font-weight: 600 !important;
}


a.text-success:hover,
a.text-success:focus {
  color: #24a46d !important;
}

.text-warning {
  color: #fb6340 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #fa3a0e !important;
}

.text-danger {
  color: #f5365c !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #ec0c38 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover,
a.text-white:focus {
  color: #e6e6e6 !important;
}

.text-muted {
  color: #8898aa !important;
}

@media print {
  *,
  *::before,
  *::after {
    box-shadow: none !important;
    text-shadow: none !important;
  }
  
  a:not(.btn) {
    text-decoration: underline;
  }
  
  p,
  h2 {
    orphans: 3;
    widows: 3;
  }
  
  h2 {
    page-break-after: avoid;
  }
  
  /* @ page {
    size: a3;
  } */
  
  body {
    min-width: 992px !important;
  }
}

figcaption,
main {
  display: block;
}

main {
  overflow: hidden;
}

.bg-yellow {
  background-color: #ffd600 !important;
}






.icon {
  width: 3rem;
  height: 3rem;
}

.icon i {
  font-size: 30px !important;
}

.icon-shape {
  display: inline-flex;
  padding: 12px;
  text-align: center;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.main{
    background: rgb(0, 54, 101) !important;
}







.Doughnut{
    height: 350px !important;
    width: 350px !important;
    display: inline !important;
}






/* .button-group {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: auto;
	padding: 10px;
	background: #fff;
	box-shadow: 0 0 20px rgba(0,0,0,.3);
	border-radius: 100px;
	position: relative;
	margin-top: 50%;
	
	&::before {
		content: '';
		position: absolute;
		width: 50%;
		bottom:0;
		top:0;
		left: 0;
		margin: 10px;
		border-radius: 100px;
		background-image: radial-gradient(89% 83%, #F79F00 10%, #F7B500 100%);
	}
	
	&.left {
		&::before {
			transform: translatex(0);
			transition: all 0.8s cubic-bezier(1, -0.01, 0, 1.01);
		}
	}
	
	&.right {
		&::before {
			transform: translatex(calc(100% - 20px));
			transition: all 0.8s cubic-bezier(1, -0.01, 0, 1.01);
		}
	}
	
	.button {
		width: 50%;
		padding: 10px;
		text-align: center;
		position: relative;
		z-index: 1;
		cursor: pointer;
		color: #4d4d4d;
		transition: color 0.8s cubic-bezier(1, -0.01, 0, 1.01);
		&.active {
			color: #fff;
			transition: color 0.8s cubic-bezier(1, -0.01, 0, 1.01);
		}
	}
} */