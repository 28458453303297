eslint-disable-next-line

.btnclr {
    background: darkblue;
    color: white;
    text-align: center;
}

.main-header{
	/* width: 258px; */
	margin-left:258px;
}

.nav-treeview:after {
    transition: 0.4s;
}


.angle{
    transform: rotate(90deg);
}

.navbar-nav{
    flex-direction: row !important;
}


.iconClass {
    color: #ffffff;
    font-size: 5px;
    line-height: 5px;
    position: absolute;
    top: 19px;
}


.button-group {
	display: flex;
	justify-content: space-between;
	width: 180%;
	height: auto;
	padding: 0px;
	background: #fff;
	box-shadow: 0 0 20px rgba(0,0,0,.3);
	border-radius: 100px;
	position: relative;
	/* margin-top: 50%; */
	
	&::before {
		content: '';
		position: absolute;
		width: 50%;
		bottom:0;
		top:0;
		left: 20;
        right: 10;
		
		/* margin: 6px 10px 6px 10px; */
		border-radius: 100px;
		background-image: radial-gradient(89% 83%, rgb(0, 54, 101) 10%, rgb(0, 54, 101) 100%);
	}
	
	&.left {
		&::before {
			transform: translatex(0);
			transition: all 0.2s cubic-bezier(1, -0.01, 0, 1.01);
            margin: 6px 10px 6px 4px;
		}
	}
	
	&.right {
		&::before {
			transform: translatex(calc(100% - 20px));
			transition: all 0.2s cubic-bezier(1, -0.01, 0, 1.01);
            margin: 6px 6px 6px 14px;
		}
	}
	
	.button {
		width: 80%;
		padding: 10px;
		text-align: center;
		position: relative;
		z-index: 1;
		cursor: pointer;
		color: #4d4d4d;
		transition: color 0.8s cubic-bezier(1, -0.01, 0, 1.01);
		&.active {
			color: #fff;
			transition: color 0.8s cubic-bezier(1, -0.01, 0, 1.01);
		}
		
	}
}
